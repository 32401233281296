import {makeStyles} from "@mui/styles";

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";

export default makeStyles((theme) => ({

    houseMiniItemWrapper: {
        //width: 160,
        width: 322,
        background: "#F4F6F9",
        borderRadius: 8,
        border: "1px solid rgba(0, 32, 51, 0.05)",
        overflow: "hidden",
        position: "relative",
        //boxShadow: "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 2px 8px rgba(0, 32, 51, 0.16)",
        margin: 5,
        marginBottom: 40,
        flex: "none",
        boxShadow: "0px 2px 8px 0px #00203329",
    },
    houseMiniItemLoading: {
        width: 322,
        margin: 5,
        flex: "none",
        "display": "flex",
        "alignItems": "center",
        justifyContent: "center",
        "& span": {
            textAlign: "center"
        }
    },
    houseMiniItemImage: {
        position: "relative",
        width: "100%",
        height: 163,
        "& img": {
            height: 163,
            width: "100%",
            objectFit: "cover",
        },

        "& .catalogPublishedAt": {
            height: 70,
            width: "100%",
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: "0 0 4px 6px",
            background: "linear-gradient(360deg, #202020 0%, rgba(32, 32, 32, 0) 100%)",
            display: "flex",
            alignItems: "flex-end",
            "& span": {
                fontWeight: 600,
                fontSize: 12*onePx+"rem",
                color: "#fff",
            },
        },
        "& .catalogNotPublished::before": {
            "display": "block",
            "content": '""',
            "width": 8,
            "height": 8,
            borderRadius: "50%",
            marginRight: 6,
            marginBottom: 5,
            background: "#EB3333",
        },


    },
    houseMiniItemName: {
        fontFamily: "Inter",
        fontSize: 17*onePx+"rem",
        fontWeight: 700,
        color: "#2F2E36",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    catalogPublishedAt: {

    },
    houseMiniItemNotImage: {
        background: "#F4F6F9",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "& .catalogPublishedAt": {
            background: "linear-gradient(360deg, rgba(32, 32, 32, 0.1) 0%, rgba(32, 32, 32, 0) 100%)",
            "& span": {
                color: "rgba(24, 43, 61, 0.52)!important",
            },
        }
    },
    houseMiniItemContent: {
        height: 95,
        background: "#fff",
        display: "flex",
        padding: "0 10px",
        flexDirection: "column",
        justifyContent: "center",
    },
    houseMiniItemContentWithOutContact: {
        height: 86,
    },

    houseMiniItemAddress: {
        "color": "#2F2E36",
        "height": 24,
        "display": "flex",
        "overflow": "hidden",
        "margin": 0,
        fontFamily: "Inter",
        "fontSize": 15*onePx+"rem",
        fontWeight: 400,
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            color: "#abb1b6",
            fontSize: 19,
            marginRight: 8,
        }
    },
    houseMiniItemContentPrice: {
        display: "flex",
        alignItems: "center",
        marginTop: 2,
        "fontSize": 13*onePx+"rem",
        "fontWeight": "700",
        "lineHeight": "0.9rem",
        margin: 0,
    },
    houseMiniItemContentAddPrice: {
        "display": "flex",
        "alignItems": "center",
        "marginTop": 2,
        "fontSize": 13*onePx+"rem",
        "fontWeight": "100",
        "lineHeight": "0.9rem",
        "margin": 0,
        color: "#657780",
    },
    popularHousesWrapper: {
        display: "flex",
        padding: "10px 0 0 10px",
        overflowX: "auto",
        height: 315,
        "&::-webkit-scrollbar": {
            display: "none",
        }
    },

    popularHouses: {
        background: "#F4F6F9",
        paddingTop: 40,
        "& h2": {
            padding: "0 15px",
            "& span": {
                marginLeft: 8,
            },
            "& .MuiSvgIcon-root": {
                color: "#FFB500",
            }
        }
    },
    houseCardInfoRating: {
        display: "flex",
        alignItems: "center",
        "& .MuiRating-root": {
            color: "#FFE146",
        },
        "& .MuiRating-icon": {
            //color: "#fff",
        },
        "& .MuiRating-iconFilled": {
            color: "#FFE146",
        },
    },
    houseCardInfoRatingVal: {
        color: "#2F2E36",
        fontWeight: 600,
        fontSize: 13*onePx+"rem",
        marginRight: 4,
    },

    ///

    popularRe: {
        padding: "40px 0",
        "& h2": {
            padding: "0 15px",
            "& span": {
                marginLeft: 8,
            },
            "& .MuiSvgIcon-root": {
                color: "#FFB500",
            }
        }
    },

    catalogMiniItemLoading: {
        //width: 160,
        width: 156,
        margin: 5,
        flex: "none",
        "display": "flex",
        "alignItems": "center",
        justifyContent: "center",
        "& span": {
            textAlign: "center"
        }
    },

    mainWrapper: {
        overflowY: "auto",
        position: "relative",
        paddingTop: 48,
    },

    ///

    banners: {
        height: 326,
        display: "flex",
        padding: "40px 0 40px 10px",
        overflowX: "auto",
        background: "#f4f6f9",
        "&::-webkit-scrollbar": {
            display: "none",
        }
    },

    bannerItem: {
        padding: 15,
        width: 318,
        background: "#F4F6F9",
        borderRadius: 8,
        border: "1px solid rgba(0, 32, 51, 0.05)",
        overflow: "hidden",
        position: "relative",
        margin: 5,
        //marginBottom: 40,
        flex: "none",
        //boxShadow: "0px 2px 8px 0px #00203329",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },

        "& .MuiButton-root": {
            position: "absolute",
            bottom: 15,
            left: 15,
            zIndex: 2,
        },
    },

    bannerItem__header: {
        position: "relative",
        zIndex: 2,
        color: "#fff",
        fontFamily: "Montserrat",
        fontSize: 14*onePx+"rem",
        fontWeight: 700,
        textTransform: "uppercase",
        marginLeft: -15,
        "& span": {
            padding: "3px 14px 3px 14px",
            boxDecorationBreak: "clone",
            webkitBoxDecorationBreak: "clone",
        }
    },

    bannerItem__cover: {
        position: "absolute",
        left:0,
        top: 0,
        right: 0,
        bottom: 0,
    },

    ///

    bookServiceApartaments: {
        padding: "40px 0",
    },

    bookServiceApartamentsHeader: {
        display: "flex",
        marginBottom: 24,
        padding: "0 15px",
        "& img": {
            marginRight: 15,
        }
    },

    bookServiceApartamentsContent: {
        padding: "0 15px",
        marginBottom: -10,
    },

    ///

    aboutAO: {
        background: "url(/svg/crm_bg.svg) no-repeat 0 0 / 100%, #fff",
        padding: "72px 15px 40px 22px",
        "& h2": {
            marginBottom: 20,
        }
    },

    aboutAOLogo: {
        marginBottom: 23,
        width: 100,
    },

    aboutAOContent: {
        "& h3": {
            fontFamily: "Montserrat",
            fontSize: 13 * onePx + "rem",
            fontWeight: 700,
            textTransform: "uppercase",
        }
    },

    footer: {

        display: "flex",
        flexDirection: "column",
    },

    footer__itemHeader: {
        display: "flex",
        alignItems: "center",
        height: 60,
        color: '#2F2E36',
        fontSize: 22*onePx+"rem",
        fontFamily: 'Montserrat',
        fontWeight: '700',
        textTransform: 'uppercase',
        padding: "0 16px",
        borderBottom: "1px solid #DEE1E6",
        lineHeight: "1.2em",
    },

    footer__item: {
        display: "flex",
        alignItems: "center",
        height: 60,
        color: '#657780',
        fontSize: 17*onePx+"rem",
        fontFamily: 'Inter',
        fontWeight: '400',
        padding: "0 16px",
        borderBottom: "1px solid #DEE1E6",
        lineHeight: "1.2em",
    },
    footer__lastItem: {
        borderBottom: "none",
    },
    footer__delimiter: {
        height: 40,
        background: "#f4f6f9",
    },

    footer_followSection: {
        height: 85,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 15px",
        background: "#f4f6f9",
    },

    footer_followSectionHeader: {
        color: '#2F2E36',
        fontSize: 12*onePx+"rem",
        fontFamily: 'Montserrat',
        fontWeight: '700',
        textTransform: 'uppercase',
        wordWrap: 'break-word',
    },
    footer_followSectionBtns: {

    },
    footer_followSectionBtn: {
        width: 35,
        height: 35,
        margin: "0 7px",
    },
    footer_copyright: {
        padding: "40px 15px",
    },
    footer_copyrightLogo: {
        "& img": {
            width: 133,
        }
    },
    footer_copyrightText: {
        color: '#2F2E36',
        fontSize: 14*onePx+"rem",
        fontFamily: 'Montserrat',
        fontWeight: '500',
        "& p": {
            margin: 0,
        }
    },

    ///publishApartments

    publishApartments: {
        padding: "54px 16px 16px 16px",
        background: "url(/images/cover_publish_apartments.jpg)",
        height: 222,
        backgroundSize: "cover",
        "& h2": {
            position: "relative",
            fontSize: 16*onePx+"rem",
            fontFamily: "Montserrat",
            color: "#fff",
            margin: 0,
            textTransform: "uppercase",
            marginBottom: 35,
            lineHeight: "1.25em",
            "& span": {
                color: "#FFD850",
            }
        },

        "& h2::before": {
            display: "block",
            position: "absolute",
            content: '""',
            top: -15,
            left: 0,
            width: 38,
            height: 4,
            background: "#00B2FF",
        },

        "& .MuiButtonBase-root": {
            background: "#00B2FF",
        }
    }


}));